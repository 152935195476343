import React from 'react';
import MailSubscribeSection from '../../Components/MailSubscribeSection/MailSubscribeSection';
import RedVector from '../../Assets/images/Ornament 12.png';
import WhiteVector from '../../Assets/images/Ornament 13.png';
import WhiteDotsVector from '../../Assets/images/white-dots.svg';
import RedDotsVector from '../../Assets/images/red-dots.svg';
import GroupPhoto from '../../Assets/images/mngmt.png';
import QouteIcon from '../../Assets/images/Quote.svg';
import QouteRedIcon from '../../Assets/images/Quote-red.svg';

const ManagementSection = () => {
  return (
    <section className=" bg-primary py-20  ">
      <div className="container h-auto xl:px-10 relative">
        <div className="grid grid-cols-1 ">
          <h2 className="text-white text-2xl font-medium mb-5">
            Message from the Management
          </h2>
          <img
            src={GroupPhoto}
            className={'border-4 border-white rounded-2xl z-10'}
            alt=""
          />
        </div>
        <div>
          <img
            src={RedVector}
            className="w-48 absolute top-72 z-0 -left-10"
            alt=""
          />
          <img
            src={WhiteVector}
            className="w-48 absolute z-0 2xl:-right-10 hidden lg:block -right-0 top-72"
            alt=""
          />
        </div>
        <div className="grid grid-cols-1 relative mt-10">
          <div className="flex justify-end">
            <div className="bg-white md:w-3/4 w-full flex justify-between p-6 rounded-xl z-10">
              <div className="w-36  ">
                <img src={QouteIcon} alt="" />
              </div>
              <div>
                <p> <br />
                  Welcome to EFS LOGISTICS! <br />
                  <br />
                  We are delighted to commemorate a significant milestone in our
                  company's journey. On March 16th, 1996, EFS LOGISTICS was
                  officially founded, marking 25 years of exceptional service
                  and growth. Our roots trace back even further, as the
                  logistics division of our esteemed parent company, UM FAWAZ
                  Est, established 43 years ago. This rich history and
                  experience have laid a strong foundation for our success.{' '}
                  <br />
                  <br />
                  From humble beginnings with limited resources, we have evolved
                  into a thriving organization comprising a dedicated team of
                  60+ professionals in Saudi Arabia alone. The past 25 years
                  have been a testament to our relentless pursuit of excellence,
                  which has earned us recognition as a professional company in
                  the market. Our approach is driven by knowledge, expertise,
                  hard work, and an unwavering commitment to providing
                  exceptional customer service, all underpinned by a sustainable
                  mindset. <br />
                  <br />
                  We owe our achievements to our hardworking and dedicated
                  staff, who have consistently gone above and beyond to deliver
                  exceptional results. Their contributions have been invaluable,
                  and we extend our heartfelt appreciation and thanks to each
                  member of our team. <br />
                  <br />
                  Every step we take at EFS LOGISTICS aligns with Saudi Arabia's
                  visionary National Vision 2030. We are proud to embody a truly
                  Saudi identity and have rapidly emerged as a pioneering and
                  highly diversified business conglomerate. This progress is a
                  testament to the dedication and perseverance of our entire
                  organization. Under the visionary leadership of His Highness
                  the Custodian of Two Holy Mosques King Salman Bin Abdul Aziz
                  and Crown Prince Mohammed Bin Salman, the Kingdom of Saudi
                  Arabia has provided us with an ideal environment to thrive and
                  explore new frontiers. We remain steadfast in our commitment
                  to align with Saudi Arabia's vision, working diligently to
                  contribute to a more prosperous and sustainable future. <br />
                  <br />
                  To our valued customers, business partners, and friends, we
                  extend our deepest gratitude for your unwavering support and
                  unwavering commitment to EFS LOGISTICS. Your trust has been
                  instrumental in our success, and we are honored to serve you.{' '}
                  <br />
                  <br />
                  As we celebrate 25 years of EFS LOGISTICS, we look forward to
                  the future with great enthusiasm. Our unwavering focus will
                  continue to be on delivering excellence, driving innovation,
                  and creating value for all stakeholders. Thank you for being a
                  part of our journey. <br />
                  <br />
                  Management <br />
                  EFS LOGISTICS
                </p>
              </div>
              <div className="w-32 flex items-end">
                <img src={QouteRedIcon} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-28 container">
        <div className="grid grid-cols-1">
          {/* <img src={WhiteDotsVector} className="w-40" alt="" /> */}
          {/* <img src={RedVector} className="w-48 absolute -bottom-20 -left-10" alt="" /> */}
          <MailSubscribeSection />

          <div className="flex justify-end">
            {/* <img src={RedDotsVector} className="w-40" alt="" /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ManagementSection;
